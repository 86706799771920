exports.components = {
  "component---src-pages-en-blueprint-blueprint-template-jsx": () => import("./../../../src/pages-en/blueprint/_blueprintTemplate.jsx" /* webpackChunkName: "component---src-pages-en-blueprint-blueprint-template-jsx" */),
  "component---src-pages-uk-404-jsx": () => import("./../../../src/pages-uk/404.jsx" /* webpackChunkName: "component---src-pages-uk-404-jsx" */),
  "component---src-pages-uk-about-us-index-jsx": () => import("./../../../src/pages-uk/about-us/index.jsx" /* webpackChunkName: "component---src-pages-uk-about-us-index-jsx" */),
  "component---src-pages-uk-andersen-company-profile-index-jsx": () => import("./../../../src/pages-uk/andersen-company-profile/index.jsx" /* webpackChunkName: "component---src-pages-uk-andersen-company-profile-index-jsx" */),
  "component---src-pages-uk-azure-crowdstrike-incident-index-jsx": () => import("./../../../src/pages-uk/azure-crowdstrike-incident/index.jsx" /* webpackChunkName: "component---src-pages-uk-azure-crowdstrike-incident-index-jsx" */),
  "component---src-pages-uk-case-studies-index-jsx": () => import("./../../../src/pages-uk/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-uk-case-studies-index-jsx" */),
  "component---src-pages-uk-company-articles-index-jsx": () => import("./../../../src/pages-uk/company/articles/index.jsx" /* webpackChunkName: "component---src-pages-uk-company-articles-index-jsx" */),
  "component---src-pages-uk-company-corporate-life-index-jsx": () => import("./../../../src/pages-uk/company/corporate-life/index.jsx" /* webpackChunkName: "component---src-pages-uk-company-corporate-life-index-jsx" */),
  "component---src-pages-uk-company-news-index-jsx": () => import("./../../../src/pages-uk/company/news/index.jsx" /* webpackChunkName: "component---src-pages-uk-company-news-index-jsx" */),
  "component---src-pages-uk-company-testimonials-index-jsx": () => import("./../../../src/pages-uk/company/testimonials/index.jsx" /* webpackChunkName: "component---src-pages-uk-company-testimonials-index-jsx" */),
  "component---src-pages-uk-consultancy-services-andersen-agreements-index-jsx": () => import("./../../../src/pages-uk/consultancy-services-andersen-agreements/index.jsx" /* webpackChunkName: "component---src-pages-uk-consultancy-services-andersen-agreements-index-jsx" */),
  "component---src-pages-uk-contact-us-index-jsx": () => import("./../../../src/pages-uk/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-uk-contact-us-index-jsx" */),
  "component---src-pages-uk-cookies-policy-index-jsx": () => import("./../../../src/pages-uk/cookies-policy/index.jsx" /* webpackChunkName: "component---src-pages-uk-cookies-policy-index-jsx" */),
  "component---src-pages-uk-event-event-page-slug-jsx": () => import("./../../../src/pages-uk/event/{EventPage.slug}.jsx" /* webpackChunkName: "component---src-pages-uk-event-event-page-slug-jsx" */),
  "component---src-pages-uk-find-developers-android-index-jsx": () => import("./../../../src/pages-uk/find-developers/android/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-android-index-jsx" */),
  "component---src-pages-uk-find-developers-angular-index-jsx": () => import("./../../../src/pages-uk/find-developers/angular/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-angular-index-jsx" */),
  "component---src-pages-uk-find-developers-back-end-index-jsx": () => import("./../../../src/pages-uk/find-developers/back-end/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-back-end-index-jsx" */),
  "component---src-pages-uk-find-developers-c-c-plus-plus-index-jsx": () => import("./../../../src/pages-uk/find-developers/c-c-plus-plus/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-c-c-plus-plus-index-jsx" */),
  "component---src-pages-uk-find-developers-dot-net-index-jsx": () => import("./../../../src/pages-uk/find-developers/dot-net/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-dot-net-index-jsx" */),
  "component---src-pages-uk-find-developers-flutter-index-jsx": () => import("./../../../src/pages-uk/find-developers/flutter/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-flutter-index-jsx" */),
  "component---src-pages-uk-find-developers-front-end-index-jsx": () => import("./../../../src/pages-uk/find-developers/front-end/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-front-end-index-jsx" */),
  "component---src-pages-uk-find-developers-golang-index-jsx": () => import("./../../../src/pages-uk/find-developers/golang/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-golang-index-jsx" */),
  "component---src-pages-uk-find-developers-index-jsx": () => import("./../../../src/pages-uk/find-developers/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-index-jsx" */),
  "component---src-pages-uk-find-developers-ionic-index-jsx": () => import("./../../../src/pages-uk/find-developers/ionic/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-ionic-index-jsx" */),
  "component---src-pages-uk-find-developers-ios-index-jsx": () => import("./../../../src/pages-uk/find-developers/ios/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-ios-index-jsx" */),
  "component---src-pages-uk-find-developers-java-index-jsx": () => import("./../../../src/pages-uk/find-developers/java/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-java-index-jsx" */),
  "component---src-pages-uk-find-developers-js-index-jsx": () => import("./../../../src/pages-uk/find-developers/js/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-js-index-jsx" */),
  "component---src-pages-uk-find-developers-node-js-index-jsx": () => import("./../../../src/pages-uk/find-developers/node-js/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-node-js-index-jsx" */),
  "component---src-pages-uk-find-developers-php-index-jsx": () => import("./../../../src/pages-uk/find-developers/php/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-php-index-jsx" */),
  "component---src-pages-uk-find-developers-python-index-jsx": () => import("./../../../src/pages-uk/find-developers/python/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-python-index-jsx" */),
  "component---src-pages-uk-find-developers-react-js-index-jsx": () => import("./../../../src/pages-uk/find-developers/react-js/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-react-js-index-jsx" */),
  "component---src-pages-uk-find-developers-react-native-index-jsx": () => import("./../../../src/pages-uk/find-developers/react-native/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-react-native-index-jsx" */),
  "component---src-pages-uk-find-developers-ruby-index-jsx": () => import("./../../../src/pages-uk/find-developers/ruby/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-ruby-index-jsx" */),
  "component---src-pages-uk-find-developers-rust-index-jsx": () => import("./../../../src/pages-uk/find-developers/rust/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-rust-index-jsx" */),
  "component---src-pages-uk-find-developers-scala-index-jsx": () => import("./../../../src/pages-uk/find-developers/scala/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-scala-index-jsx" */),
  "component---src-pages-uk-find-developers-vue-js-index-jsx": () => import("./../../../src/pages-uk/find-developers/vue-js/index.jsx" /* webpackChunkName: "component---src-pages-uk-find-developers-vue-js-index-jsx" */),
  "component---src-pages-uk-gtc-for-consultancy-services-index-jsx": () => import("./../../../src/pages-uk/gtc-for-consultancy-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-gtc-for-consultancy-services-index-jsx" */),
  "component---src-pages-uk-gtc-index-jsx": () => import("./../../../src/pages-uk/gtc/index.jsx" /* webpackChunkName: "component---src-pages-uk-gtc-index-jsx" */),
  "component---src-pages-uk-help-ukranian-software-developers-with-andersen-index-jsx": () => import("./../../../src/pages-uk/help-ukranian-software-developers-with-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-help-ukranian-software-developers-with-andersen-index-jsx" */),
  "component---src-pages-uk-index-js": () => import("./../../../src/pages-uk/index.js" /* webpackChunkName: "component---src-pages-uk-index-js" */),
  "component---src-pages-uk-industries-automotive-index-jsx": () => import("./../../../src/pages-uk/industries/automotive/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-automotive-index-jsx" */),
  "component---src-pages-uk-industries-automotive-software-defined-vehicle-index-jsx": () => import("./../../../src/pages-uk/industries/automotive/software-defined-vehicle/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-automotive-software-defined-vehicle-index-jsx" */),
  "component---src-pages-uk-industries-aviation-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/aviation-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-aviation-software-development-index-jsx" */),
  "component---src-pages-uk-industries-construction-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/construction-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-construction-software-development-index-jsx" */),
  "component---src-pages-uk-industries-custom-agriculture-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/custom-agriculture-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-custom-agriculture-software-development-index-jsx" */),
  "component---src-pages-uk-industries-custom-energy-management-software-index-jsx": () => import("./../../../src/pages-uk/industries/custom-energy-management-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-custom-energy-management-software-index-jsx" */),
  "component---src-pages-uk-industries-custom-software-for-law-firms-index-jsx": () => import("./../../../src/pages-uk/industries/custom-software-for-law-firms/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-custom-software-for-law-firms-index-jsx" */),
  "component---src-pages-uk-industries-e-learning-index-jsx": () => import("./../../../src/pages-uk/industries/eLearning/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-e-learning-index-jsx" */),
  "component---src-pages-uk-industries-ecommerce-index-jsx": () => import("./../../../src/pages-uk/industries/ecommerce/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-ecommerce-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-andersen-banking-big-data-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/andersen-banking-big-data-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-andersen-banking-big-data-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-andersen-banking-digital-transformation-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/andersen-banking-digital-transformation/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-andersen-banking-digital-transformation-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-andersen-banking-integration-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/andersen-banking-integration-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-andersen-banking-integration-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-andersen-core-banking-software-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/andersen-core-banking-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-andersen-core-banking-software-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-andersen-custom-loan-software-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/andersen-custom-loan-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-andersen-custom-loan-software-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-andersen-digital-banking-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/andersen-digital-banking-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-andersen-digital-banking-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-banking-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/banking/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-banking-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-crypto-development-services-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/crypto-development-services-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-crypto-development-services-andersen-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-custom-blockchain-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/custom-blockchain-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-custom-blockchain-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-custom-built-loan-software-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/custom-built-loan-software-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-custom-built-loan-software-andersen-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-custom-compliance-with-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/custom-compliance-with-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-custom-compliance-with-andersen-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-custom-e-money-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/custom-e-money-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-custom-e-money-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-dapps-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/dapps-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-dapps-development-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-defi-development-services-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/defi-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-defi-development-services-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-digital-banking-app-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/digital-banking-app/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-digital-banking-app-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-andersen-alternative-lending-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/andersen-alternative-lending-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-andersen-alternative-lending-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-andersen-cloud-fintech-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/andersen-cloud-fintech-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-andersen-cloud-fintech-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-andersen-cryptocurrency-development-services-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/andersen-cryptocurrency-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-andersen-cryptocurrency-development-services-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-andersen-custom-digital-wallet-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/andersen-custom-digital-wallet-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-andersen-custom-digital-wallet-development-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-andersen-fintech-payment-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/andersen-fintech-payment-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-andersen-fintech-payment-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-andersen-it-personal-finance-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/andersen-it-personal-finance-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-andersen-it-personal-finance-solutions-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-fintech-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/fintech/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-fintech-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-insurance-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/insurance/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-insurance-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-andersen-asset-management-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/andersen-asset-management-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-andersen-asset-management-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-andersen-custom-trading-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/andersen-custom-trading-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-andersen-custom-trading-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-andersen-finance-analysis-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/andersen-finance-analysis-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-andersen-finance-analysis-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-andersen-investment-compliance-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/andersen-investment-compliance-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-andersen-investment-compliance-development-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-andersen-mobile-investment-software-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/andersen-mobile-investment-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-andersen-mobile-investment-software-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-andersen-trading-api-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/andersen-trading-api-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-andersen-trading-api-development-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-investment-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/investment/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-investment-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-nft-development-services-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/nft-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-nft-development-services-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-open-banking-and-api-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/open-banking-and-api-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-open-banking-and-api-development-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-price-comparison-calculator-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/price-comparison-calculator-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-price-comparison-calculator-andersen-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-robotic-process-automation-services-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/robotic-process-automation-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-robotic-process-automation-services-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-smart-contracts-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/smart-contracts-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-smart-contracts-development-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-staff-augmentation-finance-service-companies-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/staff-augmentation-finance-service-companies/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-staff-augmentation-finance-service-companies-index-jsx" */),
  "component---src-pages-uk-industries-financial-services-tax-and-accounting-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/financial-services/tax-and-accounting-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-financial-services-tax-and-accounting-software-development-index-jsx" */),
  "component---src-pages-uk-industries-gambling-andersen-igaming-platform-development-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/andersen-igaming-platform-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-andersen-igaming-platform-development-index-jsx" */),
  "component---src-pages-uk-industries-gambling-andersen-igaming-platform-protection-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/andersen-igaming-platform-protection/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-andersen-igaming-platform-protection-index-jsx" */),
  "component---src-pages-uk-industries-gambling-andersen-igaming-production-operations-support-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/andersen-igaming-production-operations-support/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-andersen-igaming-production-operations-support-index-jsx" */),
  "component---src-pages-uk-industries-gambling-andersen-integrations-for-igaming-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/andersen-integrations-for-igaming/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-andersen-integrations-for-igaming-index-jsx" */),
  "component---src-pages-uk-industries-gambling-andersen-multi-devices-browsers-support-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/andersen-multi-devices-browsers-support/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-andersen-multi-devices-browsers-support-index-jsx" */),
  "component---src-pages-uk-industries-gambling-andersen-ui-ux-game-art-design-igaming-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/andersen-ui-ux-game-art-design-igaming/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-andersen-ui-ux-game-art-design-igaming-index-jsx" */),
  "component---src-pages-uk-industries-gambling-betting-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/betting/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-betting-index-jsx" */),
  "component---src-pages-uk-industries-gambling-igaming-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/igaming/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-igaming-index-jsx" */),
  "component---src-pages-uk-industries-gambling-index-jsx": () => import("./../../../src/pages-uk/industries/gambling/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gambling-index-jsx" */),
  "component---src-pages-uk-industries-gis-development-services-index-jsx": () => import("./../../../src/pages-uk/industries/gis-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-gis-development-services-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-ai-software-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/ai-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-ai-software-solutions-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-andersen-services-for-tech-healthcare-startups-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/andersen-services-for-tech-healthcare-startups/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-andersen-services-for-tech-healthcare-startups-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-assistive-technology-software-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/assistive-technology-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-assistive-technology-software-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-data-analytics-and-iomt-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/data-analytics-and-iomt/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-data-analytics-and-iomt-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-emr-solutions-andersen-custom-ehr-emr-development-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/emr-solutions/andersen-custom-ehr-emr-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-emr-solutions-andersen-custom-ehr-emr-development-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-emr-solutions-andersen-ehr-emr-customization-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/emr-solutions/andersen-ehr-emr-customization/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-emr-solutions-andersen-ehr-emr-customization-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-emr-solutions-andersen-ehr-emr-integration-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/emr-solutions/andersen-ehr-emr-integration/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-emr-solutions-andersen-ehr-emr-integration-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-emr-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/emr-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-emr-solutions-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-health-information-exchange-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/health-information-exchange/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-health-information-exchange-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-health-information-management-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/health-information-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-health-information-management-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-health-information-security-and-compliance-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/health-information-security-and-compliance/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-health-information-security-and-compliance-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-health-tech-future-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/health-tech-future/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-health-tech-future-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-healthcare-compliance-services-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/healthcare-compliance-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-healthcare-compliance-services-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-healthcare-software-developers-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/healthcare-software-developers/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-healthcare-software-developers-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-hospital-management-software-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/hospital-management-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-hospital-management-software-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-jeff-goldsmith-talk-about-healthcare-and-tech-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/jeff-goldsmith-talk-about-healthcare-and-tech/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-jeff-goldsmith-talk-about-healthcare-and-tech-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-medical-practice-management-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/medical-practice-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-medical-practice-management-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-mental-health-digital-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/mental-health-digital/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-mental-health-digital-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-mobile-healthcare-applications-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/mobile-healthcare-applications/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-mobile-healthcare-applications-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-pacs-dicom-software-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/pacs-dicom-software/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-pacs-dicom-software-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-remote-patient-monitoring-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/remote-patient-monitoring/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-remote-patient-monitoring-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-revenue-cycle-management-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/revenue-cycle-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-revenue-cycle-management-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-telehealth-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/telehealth-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-telehealth-solutions-index-jsx" */),
  "component---src-pages-uk-industries-healthcare-telemedicine-app-development-index-jsx": () => import("./../../../src/pages-uk/industries/healthcare/telemedicine-app-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-healthcare-telemedicine-app-development-index-jsx" */),
  "component---src-pages-uk-industries-index-jsx": () => import("./../../../src/pages-uk/industries/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-index-jsx" */),
  "component---src-pages-uk-industries-logistics-blockchain-solutions-for-logistics-supply-chains-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/blockchain-solutions-for-logistics-supply-chains/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-blockchain-solutions-for-logistics-supply-chains-index-jsx" */),
  "component---src-pages-uk-industries-logistics-custom-fleet-management-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/custom-fleet-management-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-custom-fleet-management-software-development-index-jsx" */),
  "component---src-pages-uk-industries-logistics-custom-software-car-sharing-pooling-rental-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/custom-software-car-sharing-pooling-rental/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-custom-software-car-sharing-pooling-rental-index-jsx" */),
  "component---src-pages-uk-industries-logistics-custom-supply-chain-software-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/custom-supply-chain-software-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-custom-supply-chain-software-andersen-index-jsx" */),
  "component---src-pages-uk-industries-logistics-freight-forwarding-optimization-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/freight-forwarding-optimization-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-freight-forwarding-optimization-solutions-index-jsx" */),
  "component---src-pages-uk-industries-logistics-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-index-jsx" */),
  "component---src-pages-uk-industries-logistics-inventory-management-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/inventory-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-inventory-management-index-jsx" */),
  "component---src-pages-uk-industries-logistics-order-management-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/order-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-order-management-index-jsx" */),
  "component---src-pages-uk-industries-logistics-smart-city-solutions-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/smart-city-solutions-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-smart-city-solutions-andersen-index-jsx" */),
  "component---src-pages-uk-industries-logistics-transport-management-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/transport-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-transport-management-index-jsx" */),
  "component---src-pages-uk-industries-logistics-urban-mobility-solutions-logistics-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/urban-mobility-solutions-logistics-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-urban-mobility-solutions-logistics-andersen-index-jsx" */),
  "component---src-pages-uk-industries-logistics-warehouse-management-index-jsx": () => import("./../../../src/pages-uk/industries/logistics/warehouse-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-logistics-warehouse-management-index-jsx" */),
  "component---src-pages-uk-industries-manufacturing-index-jsx": () => import("./../../../src/pages-uk/industries/manufacturing/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-manufacturing-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-digital-asset-management-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/digital-asset-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-digital-asset-management-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-live-streaming-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/live-streaming/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-live-streaming-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-marketing-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/marketing-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-marketing-software-development-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-media-security-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/media-security-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-media-security-solutions-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-social-media-applications-development-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/social-media-applications-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-social-media-applications-development-index-jsx" */),
  "component---src-pages-uk-industries-media-and-entertainment-video-on-demand-index-jsx": () => import("./../../../src/pages-uk/industries/media-and-entertainment/video-on-demand/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-media-and-entertainment-video-on-demand-index-jsx" */),
  "component---src-pages-uk-industries-nonprofit-software-development-index-jsx": () => import("./../../../src/pages-uk/industries/nonprofit-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-nonprofit-software-development-index-jsx" */),
  "component---src-pages-uk-industries-oil-and-gas-industry-custom-software-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/oil-and-gas-industry-custom-software-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-oil-and-gas-industry-custom-software-andersen-index-jsx" */),
  "component---src-pages-uk-industries-real-estate-index-jsx": () => import("./../../../src/pages-uk/industries/real-estate/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-real-estate-index-jsx" */),
  "component---src-pages-uk-industries-retail-index-jsx": () => import("./../../../src/pages-uk/industries/retail/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-retail-index-jsx" */),
  "component---src-pages-uk-industries-software-for-life-science-andersen-craig-lipset-talk-about-pharma-index-jsx": () => import("./../../../src/pages-uk/industries/software-for-life-science-andersen/craig-lipset-talk-about-pharma/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-software-for-life-science-andersen-craig-lipset-talk-about-pharma-index-jsx" */),
  "component---src-pages-uk-industries-software-for-life-science-andersen-custom-femtech-software-solutions-index-jsx": () => import("./../../../src/pages-uk/industries/software-for-life-science-andersen/custom-femtech-software-solutions/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-software-for-life-science-andersen-custom-femtech-software-solutions-index-jsx" */),
  "component---src-pages-uk-industries-software-for-life-science-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/software-for-life-science-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-software-for-life-science-andersen-index-jsx" */),
  "component---src-pages-uk-industries-software-for-life-science-andersen-lims-development-index-jsx": () => import("./../../../src/pages-uk/industries/software-for-life-science-andersen/lims-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-software-for-life-science-andersen-lims-development-index-jsx" */),
  "component---src-pages-uk-industries-software-for-life-science-andersen-software-for-medical-devices-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/software-for-life-science-andersen/software-for-medical-devices-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-software-for-life-science-andersen-software-for-medical-devices-andersen-index-jsx" */),
  "component---src-pages-uk-industries-software-for-life-science-andersen-software-for-pharmaceutical-businesses-andersen-index-jsx": () => import("./../../../src/pages-uk/industries/software-for-life-science-andersen/software-for-pharmaceutical-businesses-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-software-for-life-science-andersen-software-for-pharmaceutical-businesses-andersen-index-jsx" */),
  "component---src-pages-uk-industries-telecom-bss-oss-transformation-index-jsx": () => import("./../../../src/pages-uk/industries/telecom/bss-oss-transformation/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-telecom-bss-oss-transformation-index-jsx" */),
  "component---src-pages-uk-industries-telecom-index-jsx": () => import("./../../../src/pages-uk/industries/telecom/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-telecom-index-jsx" */),
  "component---src-pages-uk-industries-telecom-telecom-channel-solution-development-index-jsx": () => import("./../../../src/pages-uk/industries/telecom/telecom-channel-solution-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-telecom-telecom-channel-solution-development-index-jsx" */),
  "component---src-pages-uk-industries-travel-hospitality-index-jsx": () => import("./../../../src/pages-uk/industries/travel-hospitality/index.jsx" /* webpackChunkName: "component---src-pages-uk-industries-travel-hospitality-index-jsx" */),
  "component---src-pages-uk-privacy-policy-index-jsx": () => import("./../../../src/pages-uk/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-uk-privacy-policy-index-jsx" */),
  "component---src-pages-uk-project-cases-project-page-slug-jsx": () => import("./../../../src/pages-uk/project-cases/{ProjectPage.slug}.jsx" /* webpackChunkName: "component---src-pages-uk-project-cases-project-page-slug-jsx" */),
  "component---src-pages-uk-services-andersen-saas-development-services-index-jsx": () => import("./../../../src/pages-uk/services/andersen-saas-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-andersen-saas-development-services-index-jsx" */),
  "component---src-pages-uk-services-architecture-andersen-aaas-architecture-as-a-service-index-jsx": () => import("./../../../src/pages-uk/services/architecture/andersen-aaas-architecture-as-a-service/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-architecture-andersen-aaas-architecture-as-a-service-index-jsx" */),
  "component---src-pages-uk-services-architecture-index-jsx": () => import("./../../../src/pages-uk/services/architecture/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-architecture-index-jsx" */),
  "component---src-pages-uk-services-artificial-intelligence-ai-code-reviewer-index-jsx": () => import("./../../../src/pages-uk/services/artificial-intelligence/ai-code-reviewer/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-artificial-intelligence-ai-code-reviewer-index-jsx" */),
  "component---src-pages-uk-services-artificial-intelligence-ai-recruiter-index-jsx": () => import("./../../../src/pages-uk/services/artificial-intelligence/ai-recruiter/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-artificial-intelligence-ai-recruiter-index-jsx" */),
  "component---src-pages-uk-services-artificial-intelligence-ai-tools-for-business-transformation-index-jsx": () => import("./../../../src/pages-uk/services/artificial-intelligence/ai-tools-for-business-transformation/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-artificial-intelligence-ai-tools-for-business-transformation-index-jsx" */),
  "component---src-pages-uk-services-artificial-intelligence-consulting-index-jsx": () => import("./../../../src/pages-uk/services/artificial-intelligence/consulting/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-artificial-intelligence-consulting-index-jsx" */),
  "component---src-pages-uk-services-artificial-intelligence-index-jsx": () => import("./../../../src/pages-uk/services/artificial-intelligence/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-artificial-intelligence-index-jsx" */),
  "component---src-pages-uk-services-business-analysts-andersen-it-project-discovery-phase-index-jsx": () => import("./../../../src/pages-uk/services/business-analysts/andersen-it-project-discovery-phase/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-business-analysts-andersen-it-project-discovery-phase-index-jsx" */),
  "component---src-pages-uk-services-business-analysts-index-jsx": () => import("./../../../src/pages-uk/services/business-analysts/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-business-analysts-index-jsx" */),
  "component---src-pages-uk-services-business-analysts-it-project-rescue-andersen-index-jsx": () => import("./../../../src/pages-uk/services/business-analysts/it-project-rescue-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-business-analysts-it-project-rescue-andersen-index-jsx" */),
  "component---src-pages-uk-services-business-analysts-software-project-documentation-services-index-jsx": () => import("./../../../src/pages-uk/services/business-analysts/software-project-documentation-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-business-analysts-software-project-documentation-services-index-jsx" */),
  "component---src-pages-uk-services-cloud-development-services-amazon-web-services-index-jsx": () => import("./../../../src/pages-uk/services/cloud-development-services/amazon-web-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-cloud-development-services-amazon-web-services-index-jsx" */),
  "component---src-pages-uk-services-cloud-development-services-cloud-migration-services-and-consulting-index-jsx": () => import("./../../../src/pages-uk/services/cloud-development-services/cloud-migration-services-and-consulting/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-cloud-development-services-cloud-migration-services-and-consulting-index-jsx" */),
  "component---src-pages-uk-services-cloud-development-services-index-jsx": () => import("./../../../src/pages-uk/services/cloud-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-cloud-development-services-index-jsx" */),
  "component---src-pages-uk-services-cross-platform-development-index-jsx": () => import("./../../../src/pages-uk/services/cross-platform-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-cross-platform-development-index-jsx" */),
  "component---src-pages-uk-services-custom-api-integration-andersen-index-jsx": () => import("./../../../src/pages-uk/services/custom-api-integration-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-api-integration-andersen-index-jsx" */),
  "component---src-pages-uk-services-custom-software-development-backend-development-services-andersen-index-jsx": () => import("./../../../src/pages-uk/services/custom-software-development/backend-development-services-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-software-development-backend-development-services-andersen-index-jsx" */),
  "component---src-pages-uk-services-custom-software-development-data-platform-consulting-index-jsx": () => import("./../../../src/pages-uk/services/custom-software-development/data-platform-consulting/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-software-development-data-platform-consulting-index-jsx" */),
  "component---src-pages-uk-services-custom-software-development-index-jsx": () => import("./../../../src/pages-uk/services/custom-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-software-development-index-jsx" */),
  "component---src-pages-uk-services-custom-software-development-large-scale-software-development-index-jsx": () => import("./../../../src/pages-uk/services/custom-software-development/large-scale-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-software-development-large-scale-software-development-index-jsx" */),
  "component---src-pages-uk-services-custom-software-development-sme-software-development-index-jsx": () => import("./../../../src/pages-uk/services/custom-software-development/sme-software-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-software-development-sme-software-development-index-jsx" */),
  "component---src-pages-uk-services-custom-software-development-startup-software-development-company-index-jsx": () => import("./../../../src/pages-uk/services/custom-software-development/startup-software-development-company/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-custom-software-development-startup-software-development-company-index-jsx" */),
  "component---src-pages-uk-services-data-development-services-with-andersen-index-jsx": () => import("./../../../src/pages-uk/services/data-development-services-with-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-data-development-services-with-andersen-index-jsx" */),
  "component---src-pages-uk-services-data-development-services-with-andersen-sql-index-jsx": () => import("./../../../src/pages-uk/services/data-development-services-with-andersen/sql/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-data-development-services-with-andersen-sql-index-jsx" */),
  "component---src-pages-uk-services-data-science-index-jsx": () => import("./../../../src/pages-uk/services/data-science/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-data-science-index-jsx" */),
  "component---src-pages-uk-services-dedicated-team-index-jsx": () => import("./../../../src/pages-uk/services/dedicated-team/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-dedicated-team-index-jsx" */),
  "component---src-pages-uk-services-design-studio-graphic-design-services-index-jsx": () => import("./../../../src/pages-uk/services/design-studio/graphic-design-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-design-studio-graphic-design-services-index-jsx" */),
  "component---src-pages-uk-services-design-studio-index-jsx": () => import("./../../../src/pages-uk/services/design-studio/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-design-studio-index-jsx" */),
  "component---src-pages-uk-services-design-studio-mobile-design-index-jsx": () => import("./../../../src/pages-uk/services/design-studio/mobile-design/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-design-studio-mobile-design-index-jsx" */),
  "component---src-pages-uk-services-design-studio-redesign-ui-ux-index-jsx": () => import("./../../../src/pages-uk/services/design-studio/redesign-ui-ux/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-design-studio-redesign-ui-ux-index-jsx" */),
  "component---src-pages-uk-services-design-studio-top-notch-ui-ux-experts-index-jsx": () => import("./../../../src/pages-uk/services/design-studio/top-notch-ui-ux-experts/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-design-studio-top-notch-ui-ux-experts-index-jsx" */),
  "component---src-pages-uk-services-design-studio-web-design-index-jsx": () => import("./../../../src/pages-uk/services/design-studio/web-design/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-design-studio-web-design-index-jsx" */),
  "component---src-pages-uk-services-devops-index-jsx": () => import("./../../../src/pages-uk/services/devops/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-devops-index-jsx" */),
  "component---src-pages-uk-services-discovery-phase-index-jsx": () => import("./../../../src/pages-uk/services/discovery-phase/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-discovery-phase-index-jsx" */),
  "component---src-pages-uk-services-erp-development-index-jsx": () => import("./../../../src/pages-uk/services/erp-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-erp-development-index-jsx" */),
  "component---src-pages-uk-services-index-jsx": () => import("./../../../src/pages-uk/services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-index-jsx" */),
  "component---src-pages-uk-services-iot-development-services-index-jsx": () => import("./../../../src/pages-uk/services/iot-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-iot-development-services-index-jsx" */),
  "component---src-pages-uk-services-it-integration-and-optimization-index-jsx": () => import("./../../../src/pages-uk/services/it-integration-and-optimization/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-it-integration-and-optimization-index-jsx" */),
  "component---src-pages-uk-services-legacy-system-modernization-index-jsx": () => import("./../../../src/pages-uk/services/legacy-system-modernization/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-legacy-system-modernization-index-jsx" */),
  "component---src-pages-uk-services-maintenance-support-index-jsx": () => import("./../../../src/pages-uk/services/maintenance-support/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-maintenance-support-index-jsx" */),
  "component---src-pages-uk-services-managed-delivery-index-jsx": () => import("./../../../src/pages-uk/services/managed-delivery/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-managed-delivery-index-jsx" */),
  "component---src-pages-uk-services-managed-it-help-desk-services-index-jsx": () => import("./../../../src/pages-uk/services/managed-it-help-desk-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-managed-it-help-desk-services-index-jsx" */),
  "component---src-pages-uk-services-managed-soc-as-a-service-index-jsx": () => import("./../../../src/pages-uk/services/managed-soc-as-a-service/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-managed-soc-as-a-service-index-jsx" */),
  "component---src-pages-uk-services-marketing-services-and-consulting-index-jsx": () => import("./../../../src/pages-uk/services/marketing-services-and-consulting/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-marketing-services-and-consulting-index-jsx" */),
  "component---src-pages-uk-services-mobile-development-index-jsx": () => import("./../../../src/pages-uk/services/mobile-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-mobile-development-index-jsx" */),
  "component---src-pages-uk-services-mvp-development-services-andersen-index-jsx": () => import("./../../../src/pages-uk/services/mvp-development-services-andersen/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-mvp-development-services-andersen-index-jsx" */),
  "component---src-pages-uk-services-product-development-services-index-jsx": () => import("./../../../src/pages-uk/services/product-development-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-product-development-services-index-jsx" */),
  "component---src-pages-uk-services-progressive-web-app-development-index-jsx": () => import("./../../../src/pages-uk/services/progressive-web-app-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-progressive-web-app-development-index-jsx" */),
  "component---src-pages-uk-services-project-managers-index-jsx": () => import("./../../../src/pages-uk/services/project-managers/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-project-managers-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-automation-testing-services-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/automation-testing-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-automation-testing-services-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-automation-testing-services-mobile-test-automation-services-qa-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/automation-testing-services/mobile-test-automation-services-qa/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-automation-testing-services-mobile-test-automation-services-qa-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-custom-qa-services-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/custom-qa-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-custom-qa-services-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-performance-testing-services-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/performance-testing-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-performance-testing-services-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-qa-audit-services-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/qa-audit-services/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-qa-audit-services-index-jsx" */),
  "component---src-pages-uk-services-quality-assurance-services-qa-management-index-jsx": () => import("./../../../src/pages-uk/services/quality-assurance-services/qa-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-quality-assurance-services-qa-management-index-jsx" */),
  "component---src-pages-uk-services-security-management-index-jsx": () => import("./../../../src/pages-uk/services/security-management/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-security-management-index-jsx" */),
  "component---src-pages-uk-services-software-team-staff-augmentation-index-jsx": () => import("./../../../src/pages-uk/services/software-team-staff-augmentation/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-software-team-staff-augmentation-index-jsx" */),
  "component---src-pages-uk-services-web-development-custom-web-portal-development-index-jsx": () => import("./../../../src/pages-uk/services/web-development/custom-web-portal-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-web-development-custom-web-portal-development-index-jsx" */),
  "component---src-pages-uk-services-web-development-index-jsx": () => import("./../../../src/pages-uk/services/web-development/index.jsx" /* webpackChunkName: "component---src-pages-uk-services-web-development-index-jsx" */)
}

